<style scoped>
	.b-table > tbody > tr:nth-of-type(odd) {
		--bs-table-accent-bg: red !important;
		color: red !important;
	}
	.table> :not(caption) > * > * {
		padding: 5px 8px !important;
	}
	.btn-group > .btn:hover, .btn-group > .btn:active, .btn-group > .btn:focus {
		outline: none !important;
		box-shadow: none;
	}
</style>
<script>
	import Layout from "../../layouts/main"
	import PageHeader from "@/components/page-header"
	import appConfig from "@/app.config"
	import Multiselect from "vue-multiselect"
	import DatePicker from "vue2-datepicker"

	import Vue from 'vue'
	import axios from 'axios'
	import VueAxios from 'vue-axios'

	Vue.use(VueAxios, axios)
	axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
	axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('authToken')

	// window.addEventListener("beforeunload", () => {
	// 	localStorage.removeItem('corporateId')
	// 	localStorage.removeItem('isDashboardView')
	// })

	export default {
		page: {
			title: "",
			meta: [
				{
					name: "description",
					content: appConfig.description,
				},
			],
		},
		components: {
			Layout,
			PageHeader,
			Multiselect,
			DatePicker,
		},
		data() {
			return {
				isReportTableBusy: false,
				excelDownloading: false,
				detailedExcelDownloading: false,
				tableData: [],
				tableDataMonthly: [],
				var1: [],
				var2: [],
				title: "Attendance",
				tutorials: {
					name: 'Introduction',
					steps: [
						{
							target: '.daily',
							content: 'To get daily attendance of employee',
							nextStep: 'click',
						},
						{
							target: '.monthly',
							content: 'To get last 30 days attendance of employee',
							nextStep: 'click',
						},
					],
				},
				items: [
					{
						text: "View",
					},
					{
						text: "Attendance",
						active: true,
					},
				],
				totalRows: 1,
				currentPage: 1,
				perPage: 10,
				pageOptions: [10, 25, 50, 100, 500],
				filter: null,
				filterOn: [],
				sortBy: "age",
				sortDesc: false,
				moment: this.$moment,
				employeeFilterBranch: [],
				selectedBranch: null,
				corporateId: '',
				fields: [
					{
						key: "Action",
						tdClass: "align-center align-middle",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "EmployeeName",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "InTime",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "InLocation",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "OutTime",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "OutLocation",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "attendance",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
				],
				fieldsM: [
					/*{
						key: "employeeID",
						label: 'Employee ID',
						sortable: true,
						tdClass: "align-center",
						thStyle: { backgroundColor: '#f6f6f6' }
					},*/
					{
						key: 'actions',
						label: "Actions",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'name',
						label: "Employee Name",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'branch',
						label: "Branch",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'shift',
						label: "Shift",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'presentDays',
						label: "Present",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'absentDays',
						label: "Absent",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "leaveDays",
						label: "Leave",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "totalHours",
						label: "Total Hours",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "overtime",
						label: "Overtime",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "lateArrivals",
						label: "Late Arrivals",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "earlyDepartures",
						label: "Early Departures",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
				],
				attendance: "",
				attendanceFilterOptions: [
					"Present",
					"In",
					"Absent",
				],
				dateType: "",
				daterange: [],
				date: new Date(),
				path: "",
				firstActive: "active",
				secondActive: "",
				thirdActive: "",
				totalPresent: 0,
				totalAbsent: 0,
				totalOnLeave: 0,
				totalEmployee: 0,
				varr: 0,
				dayDifferenceMonthly: 0,
				timezone: '',
			};
		},
		computed: {
			/**
			 * Total no. of records
			 */
			rows() {
				return this.firstActive === 'active' ? this.totalPresent : this.tableDataMonthly.length;
			},
		},
		created: function() {
			if (localStorage.getItem('authToken') === null) {
				this.$router.push('/');
			}
		},
		mounted: function() {
			// if (this.$route.params.corporateId !== '') {
			// 	this.corporateId = this.$route.params.corporateId
			// }
			// Set the initial number of
			if (this.firstActive == "active") {
				this.totalRows = this.totalEmployee;
			}
			this.corporateId = localStorage.getItem('corporateId');
			this.getBranchList()
			// this.checkUserRights();
		},
		methods: {
			/**
			 * Search the table data with search input
			 */
			getBranchList: function() {
				this.axios.post('corporate/get-branch', {
					corporateId: (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId,
				}).then(result => {
					this.employeeFilterBranch = result.data.data
					this.selectedBranch = this.employeeFilterBranch[0]
					this.getAllAttendance()
				}).catch(error => {
					console.log(error)
				})
			},
			getColor(status) {
				if (status === 'Present')
					return "badge badge-pill bg-soft-success font-size-13 bg-soft-success"
				if (status === 'In')
					return "badge badge-pill bg-soft-success font-size-13 bg-soft-warning"
				return "badge badge-pill bg-soft-success font-size-13 bg-soft-danger";
			},
			onFiltered(filteredItems) {
				// Trigger pagination to update the number of buttons/pages due to filtering
				this.totalRows = filteredItems.length;
				this.currentPage = 1;
			},
			getAllAttendance() {
				this.isReportTableBusy = true
				this.axios.post("getEmployeeAttendance", {
					corpBranchId: this.selectedBranch._id,
					date: this.moment(this.date).format('YYYY-MM-DD'),
					status: this.attendance,
					page: this.currentPage,
					pageSize: this.pageSize,
				}).then((response) => {
					// console.log(response.data);
					var statusCode = response.data.responseCode;
					if (statusCode === 200) {
						this.isReportTableBusy = false;
						this.tableData = response.data.data.attendanceData;
						this.totalEmployee = response.data.data.totalEmployee;
						this.totalPresent = response.data.data.presentEmployee;
						this.totalAbsent = response.data.data.absentEmployee;
						this.totalOnLeave = response.data.data.leaveEmployee;
						//this.dayDifferenceMonthly = response.data.data.dayDifference + 1;
						this.var1 = response.data.data.dataMonthly;
						this.var2 = response.data.data.minWorkingHours;
						//this.overtime();
						this.tableDataMonthly = this.var1;
					} if (statusCode === 400) {
						console.log("Data Monthly: " + response.data.dataMonthly)
						this.$toast.error(response.data.response, {
							position: "top-right",
							timeout: 5000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: false,
							closeButton: "button",
							icon: true,
							rtl: false
						})
					}
				}).catch((error) => {
					console.log(error)
				});
			},
			getMonthlyAttendance: function() {
				this.isReportTableBusy = true;
				this.axios.post("getAdminMonthlyAttendance", {
					'corpBranchId': this.selectedBranch._id,
					'dateRange': this.daterange,
					'date': this.date,
					'status': this.attendance,
				}).then((response) => {
					// console.log(response.data);
					var statusCode = response.data.responseCode
					this.isReportTableBusy = false
					if (statusCode === 200) {
						this.tableDataMonthly = response.data.data.attendanceData;
						this.dayDifferenceMonthly = response.data.data.dayDifference + 1;
						//this.overtime();
						//this.tableDataMonthly = this.var1;
					}
					if (statusCode === 400) {
						console.log("Data Monthly: " + response.data.dataMonthly)
						this.$toast.error(response.data.data, {
							position: "top-right",
							timeout: 5000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: false,
							closeButton: "button",
							icon: true,
							rtl: false
						})
					}
				}).catch((error) => {
					console.log(error)
				});
			},
			clearFilter: function() {
				let now = new Date()
				let lastMonth = new Date(new Date(now).setDate(now.getDate() - 30))
				this.date = now
				this.daterange = [lastMonth, now]
				this.dateType = ""
				this.attendance = ""
				this.selectedBranch = this.employeeFilterBranch[0]
				if (this.firstActive === 'active') {
					this.getAllAttendance()
				} else {
					this.getMonthlyAttendance()
				}
			},
			applyFilter: function() {
				if (this.firstActive === 'active') {
					this.daterange = [];
					this.getAllAttendance()
					//return
				} else {
					this.getMonthlyAttendance()
				}
			},
			overtime: function() {
				for (let i = 0; i < this.var1.length; i++) {
					this.varr = this.var1[i].ActiveHours - this.var2[i].WorkingHours
					if (this.varr > 0)
						this.var1[i].Overtime = this.var1[i].ActiveHours - this.var2[i].WorkingHours;
					else {
						this.var1[i].Overtime = 0;
					}
				}
			},
			filterDataByRange: function(range) {
				var now = new Date();
				switch (range) {
					case 1:
						this.firstActive = "active";
						this.secondActive = "";
						this.thirdActive = "";
						this.date = now;
						this.getAllAttendance();
						break;
					/*case 2:
						this.firstActive = "";
						this.secondActive = "active";
						this.thirdActive = "";
						var lastWeek = new Date(new Date(now).setDate(now.getDate() - 7));
						this.daterange = [lastWeek, now];
						this.getAllAttendance();
						break;*/
					case 3:
						this.firstActive = "";
						this.secondActive = "";
						this.thirdActive = "active";
						var lastMonth = new Date(new Date(now).setDate(now.getDate() - 30));
						this.daterange = [lastMonth, now];
						this.getMonthlyAttendance();
						break;
				}
			},
			changePage: function(value) {
				this.currentPage = value
				this.getAllAttendance()
			},
			changePageSize: function(value) {
				this.pageSize = value
				this.getAllAttendance()
			},
			downloadReport: function() {
				this.excelDownloading = true;
				let startDate = ''
				let endDate = ''
				if (this.firstActive == 'active') {
					startDate = this.moment(this.date).format('YYYY-MM-DD 00:00:00')
					endDate = this.moment(this.date).format('YYYY-MM-DD 23:59:59')
				}
				if (this.thirdActive == 'active') {
					startDate = this.moment(this.daterange[0]).format('YYYY-MM-DD 00:00:00')
					endDate = this.moment(this.daterange[1]).format('YYYY-MM-DD 23:59:59')
				}
				console.log(startDate, endDate)
				this.axios.post("admin/download-attendance-via-date-range",{
					startDate: startDate,
					endDate: endDate,
				},{
					responseType: "blob",
				}).then((response) => {
					this.excelDownloading = false;
					const url = URL.createObjectURL(
						new Blob([response.data], {
							type: "application/vnd.ms-excel",
						})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "attendance-report.xlsx");
					document.body.appendChild(link);
					link.click();
				});
			},
			downloadDetailedReport: function() {
				this.detailedExcelDownloading = true
				let startDate = this.moment(this.daterange[0]).format('YYYY-MM-DD 00:00:00')
				let endDate = this.moment(this.daterange[1]).format('YYYY-MM-DD 23:59:59')
				this.axios.post("admin/download-detailed-attendance-via-date-range",{
					startDate: startDate,
					endDate: endDate,
				},{
					responseType: "blob",
				}).then((response) => {
					this.detailedExcelDownloading = false
					const url = URL.createObjectURL(
						new Blob([response.data], {
							type: "application/vnd.ms-excel",
						})
					);
					const link = document.createElement("a")
					link.href = url
					link.setAttribute("download", "detailed-attendance-report.xlsx")
					document.body.appendChild(link)
					link.click()
				});
			},
			headers: function() {},
		},
		middleware: "authentication",
	};
</script>
<template>
	<Layout>
		<PageHeader :items="items" />
		<!--FILTER START------>
		<div class="card" style="margin-top: -28px">
			<div class="card-body">
				<div class="inner mb-2 row">
					<div class="col-md-2" style="width:min-content">
						<label>Filter</label>
						<div class="check-group btn-group-toggle btn-group bv-no-focus-ring" style="height:auto;"
							role="group" aria-label="Date options">
							<button type="button" class="btn btn-outline-primary daily" :class="`${firstActive}`"
								v-on:click="filterDataByRange(1)">Daily</button>
							<button type="button" class="btn btn-outline-primary monthly" :class="`${thirdActive}`"
								v-on:click="filterDataByRange(3)">Monthly</button>
						</div>
					</div>
					<!-- Date range -->
					<div class="col-md-2" style="width: 20%" v-if="this.firstActive == 'active'">
						<label>Date </label>
						<date-picker v-model="date" format="DD MMM Y" append-to-body lang="en">
						</date-picker>
					</div>
					<div class="col-md-2" style="width: 20%" v-else>
						<label>Date Range </label>
						<date-picker v-model="daterange" format="DD MMM Y" append-to-body range lang="en">
						</date-picker>
					</div>
					<!-- Date range END-->
					<!-- Attendance Type -->
					<div class="col-md-2" style="width: 18%;" v-if="this.firstActive == 'active'">
						<label>Select </label>
						<multiselect v-model="attendance" :options="attendanceFilterOptions" :show-labels="false" />
					</div>
					<!-- Attendance Type END-->
					<!-- Branch -->
					<div class="col-md-4" v-if="$storageData.isSuper">
						<label for="branch">Select Branch</label>
						<multiselect id="branch" v-model="selectedBranch" :options="employeeFilterBranch" placeholder="Select Branch" :multiple="false" track-by="name" label="name" :allow-empty="false">
							<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						</multiselect>
					</div>
					<!-- Branch END -->
					<!-- Apply filter -->
					<div class="col-md-2" style="width: auto;">
						<button class="btn btn-outline-primary mt-4" v-on:click="applyFilter()">Apply Filter</button>
					</div>
					<!-- Apply filter END-->
					<!-- Clear filter -->
					<div class="col-md-2" style="width: auto;">
						<button class="btn btn-outline-danger mt-4" v-on:click="clearFilter()">Clear Filter</button>
					</div>
					<!-- Clear filter END-->
				</div>
			</div>
		</div>
		<!--FILTER END------>
		<!--CARDS START  -->
		<div class="row" v-if="this.firstActive == 'active'" style="margin: 10px 0 0">
			<div class="col-md-6 col-xl-3 overviewCard">
				<div class="card">
					<div class="card-body">
						<div class="float-end mt-2">
						</div>
						<div>
							<h4 class="cardTitle mb-1 mt-1">
								{{ this.totalPresent }}/{{ this.totalEmployee }}
							</h4>
							<p class="text-danger card-title mb-0">Total Present</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6 col-xl-3 overviewCard">
				<div class="card">
					<div class="card-body">
						<div class="float-end mt-2">
						</div>
						<div>
							<h4 class="cardTitle mb-1 mt-1">
								{{ this.totalOnLeave }}/{{ this.totalEmployee }}
							</h4>
							<p class="text-danger card-title mb-0">On Leave</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6 col-xl-3 overviewCard">
				<div class="card">
					<div class="card-body">
						<div class="float-end mt-2">
						</div>
						<div>
							<h4 class="cardTitle mb-1 mt-1">
								{{ (this.totalEmployee - this.totalPresent - this.totalOnLeave) }}/{{ this.totalEmployee }}
							</h4>
							<p class="text-danger card-title mb-0">Total Absent</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--CARDS END-->
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="row mt-4">
							<div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
								<div role="group" class="btn-group">
									<button type="button" class="btn btn-outline-primary" @click="downloadReport()">
										<span>Download Report</span>
										<b-spinner v-if="excelDownloading" small type="grow"></b-spinner>
										<b v-if="excelDownloading">Loading...</b>
									</button>
									<button v-if="this.thirdActive == 'active'" type="button" class="btn btn-outline-primary" @click="downloadDetailedReport()">
										<span>Detailed Summery Report</span>
										<b-spinner v-if="detailedExcelDownloading" small type="grow"></b-spinner>
										<b v-if="detailedExcelDownloading">Loading...</b>
									</button>
								</div>
							</div>
							<div class="col-sm-12 col-md-4">
								<div id="tickets-table_length" class="dataTables_length">
									<label class="d-inline-flex align-items-center">
										<span>Show</span>
										<b-form-select
											style="margin-left:5px; margin-right:5px"
											v-model="perPage"
											size="sm"
											:options="pageOptions"
											@change="changePageSize" />
										<span>entries</span>
									</label>
								</div>
							</div>
							<!-- Search -->
							<div class="col-sm-12 col-md-4 row">
								<div id="tickets-table_filter" class="dataTables_filter text-md-end">
									<label class="d-inline-flex align-items-center">
										<span>Search:</span>
										<b-form-input
											v-model="filter"
											type="search"
											placeholder="Search..."
											class="form-control form-control-sm ms-2" />
									</label>
								</div>
							</div>
							<!-- End search -->
						</div>
						<!-- Table -->
						<div class="table-responsive mb-0" v-if="this.firstActive == 'active'">
							<b-table striped hover outlined bordered :items="tableData" :fields="fields"
								thead-class="bg-transparent" responsive="sm" :per-page="0"
								:current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
								:filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
								:headers="headers" fixed-header :busy="isReportTableBusy" show-empty>
								<template #table-busy>
									<div class="text-center text-danger my-2">
										<b-spinner variant="primary" class="align-middle" type="grow"></b-spinner>
										<strong class="text-primary">Loading...</strong>
									</div>
								</template>
								<template v-slot:cell(attendance)="{ value }">
									<span :class="`${getColor(value)}`">{{ value }}</span>
								</template>
								<template v-slot:cell(InTime)="{ value }">
									<span v-if="value == ''">N/A</span>
									<span v-else>{{ moment(value).format('YYYY-MM-DD hh:mm A') }}</span>
								</template>
								<template v-slot:cell(OutTime)="{ value }">
									<span v-if="value == ''">N/A</span>
									<span v-else>{{ moment(value).format('YYYY-MM-DD hh:mm A') }}</span>
								</template>
								<template v-slot:cell(Action)="{ item }">
									<router-link
										:to="{ name: 'attendanceForm', params: { attendanceId: item.employeeAttendanceId, type: 'Edit' } }">
										<i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;"></i>
									</router-link>
								</template>
								<template v-slot:cell(InLocation)="data">
									<a v-if="data.item.urlPunchIn" target="_blank" :href=data.item.urlPunchIn>View Location</a>
								</template>
								<template v-slot:cell(OutLocation)="data">
									<a v-if="data.item.urlPunchOut" target="_blank" :href=data.item.urlPunchOut>View Location</a>
								</template>
								<!-- <template v-slot:cell(InLocation)="data">
									<a v-if="data.item.in_latitude" target="_blank"
										:href="'https://www.google.com/maps?q=' + data.item.in_latitude + ',' + data.item.in_longitude">View
										Location</a>
								</template>
								<template v-slot:cell(OutLocation)="data">
									<a v-if="data.item.out_latitude" target="_blank"
										:href="'https://www.google.com/maps?q=' + data.item.out_latitude + ',' + data.item.out_longitude">View
										Location</a>
								</template> -->
							</b-table>
						</div>
						<div class="table-responsive mb-0" v-else-if="this.thirdActive == 'active'">
							<b-table striped hover outlined bordered :items="tableDataMonthly" :fields="fieldsM"
								thead-class="bg-transparent" responsive="sm" :per-page="0"
								:current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
								:filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
								:headers="headers" fixed-header :busy="isReportTableBusy" show-empty>
								<template #table-busy>
									<div class="text-center text-danger my-2">
										<b-spinner variant="primary" class="align-middle" type="grow"></b-spinner>
										<strong class="text-primary">Loading...</strong>
									</div>
								</template>
								<template v-slot:cell(actions)="{ item }">
									<!-- <router-link
										:to="{
											name: 'attendanceForm',
											params: {
												attendanceId: item.attendanceId,
												type:'edit',
											},
										}">
										<i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;"></i>
									</router-link> -->
									<router-link
										:to="{
											name: 'employee-attendance',
											params: {
												employeeId: item.employeeID,
												type: 'view',
												name: item.name,
												branch: item.branch,
												shift: item.shift,
											}
										}">
										<i class="mdi mdi-eye" title="View Attendance" style="font-size: 19px;"></i>
									</router-link>
								</template>
								<template v-slot:cell(presentDays)="{ value }">
									{{ value }}/{{ dayDifferenceMonthly }}
								</template>
								<template v-slot:cell(absentDays)="{ value }">
									{{ value }}/{{ dayDifferenceMonthly }}
								</template>
								<template v-slot:cell(leaveDays)="{ value }">
									({{ value }})
								</template>
							</b-table>
						</div>
						<div class="row" v-if="this.firstActive === 'active'">
							<div class="col">
								<div class="dataTables_paginate paging_simple_numbers float-end">
									<ul class="pagination pagination-rounded mb-0">
										<!-- pagination -->
										<b-pagination
											v-model="currentPage"
											:total-rows="rows"
											:per-page="perPage"
											@change="changePage" />
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>